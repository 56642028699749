<template>
  <app-page-hero-banner
    class="@container"
    title="Prenez de l'avance en apprenant des meilleurs"
  >
    <template #subtitle>
      <p class="max-w-[600px]">
        Nos experts vous partagent leurs méthodes et leur “secret sauce” lors de
        formations exclusives.
      </p>
    </template>
    <template #cta>
      <div class="flex flex-wrap gap-4 @lg:flex-nowrap">
        <growth-contact-button
          class="basis-full @lg:basis-auto"
          origin="home"
          origin_page_action="hero_cta"
          size="xl"
        >
          Demander une démo
        </growth-contact-button>

        <app-button
          class="basis-full @lg:basis-auto"
          color="light"
          size="xl"
          :to="{ name: 'catalog-index-top-trainers' }"
          variant="tonal"
        >
          Voir nos trainers
        </app-button>
      </div>

      <div v-if="links.length > 0" class="mt-12 hidden md:block tall:mt-20">
        <p class="mb-3 font-semibold">Thématiques populaires</p>

        <ul class="flex flex-wrap gap-2">
          <li v-for="link in links" :key="link.label">
            <app-chip
              class="bg-primary-950/50 transition hover:bg-white/5 active:scale-95"
              :to="link.to"
            >
              {{ link.label }}
            </app-chip>
          </li>
        </ul>
      </div>
    </template>

    <template #aside>
      <nuxt-link
        aria-label="Pollen catalog"
        class="grid h-full grid-cols-[60%_1fr] gap-3 [grid-template-areas:'side_top'_'side_bottom'] lg:grid-cols-2"
        :to="{ name: 'catalog' }"
      >
        <brand-floating-label-slot
          v-for="(item, index) in list"
          :key="item.video_url"
          class="relative"
          :class="{
            '[grid-area:side]': index === 0,
            '[grid-area:top]': index === 1,
            '[grid-area:bottom]': index === 2,
          }"
          :color="
            item.teacher?.mainTag?.color
              ? hexToHSL(item.teacher.mainTag.color, {
                  luminance: 60,
                  saturation: 80,
                })
              : undefined
          "
        >
          <video
            v-if="item.video_url"
            autoplay
            class="absolute h-full w-full rounded-lg object-cover object-top"
            disablePictureInPicture
            loop
            muted
            playsinline
            preload="metadata"
            :src="item.video_url"
          />

          <template v-if="item.teacher" #label>
            <p class="text-sm font-semibold">
              {{ item.teacher.full_name }}
            </p>
            <p class="text-xs">
              {{ item.teacher.job }}<br />
              <strong>
                {{
                  item.teacher.companies
                    .map((company) => company.name)
                    .join(", ")
                }}
              </strong>
            </p>
          </template>
        </brand-floating-label-slot>
      </nuxt-link>
    </template>
  </app-page-hero-banner>
</template>

<script lang="ts" setup>
import { type RouteLocationRaw } from "#vue-router";
import type { TagPageListView } from "~/business-areas/tag-page/composables/tag-page-list.hook";
import type { I18nMessage } from "~/core/i18n/i18n.model";
import { useHomePageHeroVideoList } from "~/pages/index/_includes/composables/home-page-hero-video-list.hook";

const properties = defineProps<{
  tagPages: TagPageListView[];
}>();

const { list } = useHomePageHeroVideoList();

const links = computed<{ to: RouteLocationRaw; label: I18nMessage }[]>(() => {
  return properties.tagPages.slice(0, 5).map((page) => {
    return {
      label: page.tag.label,
      to: page._links.page,
    };
  });
});
</script>
