<template>
  <app-page-gradient-background>
    <home-page-hero :tag-pages="tagPages" />

    <app-page-wrapper class="mt-16 flex flex-col gap-16 md:gap-24">
      <brand-customer-logos-banner />

      <app-page-subsection
        v-if="nextSessions.length > 0"
        title="Prochaines sessions"
        :to="{ name: 'catalog-index-search' }"
      >
        <course-card-carousel id="home_course_list" :list="nextSessions" />
      </app-page-subsection>

      <home-page-academy-banner />

      <app-page-subsection
        v-if="pinnedTeachers.length > 0"
        title="À la une"
        :to="{ name: 'catalog-index-top-trainers' }"
      >
        <teacher-card-carousel :list="pinnedTeachers" show-description-short />
      </app-page-subsection>
    </app-page-wrapper>

    <app-page-wrapper
      class="my-16 !max-w-[calc(theme(screens.3xl)+theme(spacing.24))] md:my-24"
    >
      <div
        class="rounded-lg bg-accent-50 p-6 text-accent-950 md:px-12 md:py-24"
      >
        <home-page-how-it-works />
      </div>
    </app-page-wrapper>

    <app-page-wrapper class="flex flex-col gap-16 md:gap-24">
      <app-page-subsection
        v-if="tagPages.length > 0"
        title="Des experts dans chaque domaine"
        :to="{ name: 'catalog' }"
      >
        <tag-page-card-carousel :list="tagPages" />
      </app-page-subsection>

      <brand-nps-banner>
        <template #append>
          <growth-contact-button
            color="dark"
            origin="home"
            origin_page_action="nps_banner_cta"
            size="xl"
          />
        </template>
      </brand-nps-banner>

      <press-list />

      <brand-qualiopi-banner />
    </app-page-wrapper>
  </app-page-gradient-background>
</template>

<script lang="ts" setup>
import { startOfTomorrow } from "date-fns";

import TagPageCardCarousel from "~/business-areas/tag-page/components/tag-page-card-carousel.vue";
import HomePageAcademyBanner from "~/pages/index/_includes/components/home-page-academy-banner.vue";
import HomePageHero from "~/pages/index/_includes/components/home-page-hero.vue";
import HomePageHowItWorks from "~/pages/index/_includes/components/home-page-how-it-works.vue";

const { data: nextSessions } = useCourseCardList(
  "home",
  {
    from: startOfTomorrow().toISOString(),
    limit: 20,
  },
  { lazy: true },
);

const { data: pinnedTeachers } = useTeacherList(
  "pinned_teachers",
  {
    pinned: true,
  },
  {
    server: false,
    lazy: true,
  },
);

const { data: tagPages } = useTagPageList();
</script>
