<template>
  <app-page-section
    class="!gap-4 rounded-xl bg-deco2 p-6 sm:!gap-6 md:p-12"
    subtitle="Co-créez votre école interne pour vos hauts potentiels et vos leaders"
    title="Découvrez la Pollen Academy Leadership et IA"
  >
    <template #prepend>
      <div>
        <div class="mb-6 flex gap-4">
          <p
            v-motion
            class="-left-[40px] hidden max-w-[250px] md:block"
            :class="messageBubbleClass"
            :initial="{ opacity: 0, y: 20 }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 300,
                duration: 200,
                type: 'spring',
                stiffness: 250,
                damping: 25,
                mass: 0.5,
              },
            }"
          >
            Les outils d'IA m’ont fait gagner 30% de productivité !
            <span
              class="absolute -bottom-2 right-10 h-0 w-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-black"
            />
          </p>
          <p
            v-motion
            class="-left-6 top-[20px] hidden max-w-[200px] md:block"
            :class="messageBubbleClass"
            :initial="{ opacity: 0, y: 20 }"
            :visibleOnce="{
              opacity: 1,
              y: 0,
              transition: {
                delay: 500,
                duration: 200,
                type: 'spring',
                stiffness: 250,
                damping: 25,
                mass: 0.5,
              },
            }"
          >
            Super, ils rapportent les croissants aussi ?
            <span
              class="absolute -bottom-2 left-16 h-0 w-0 border-l-8 border-r-8 border-t-8 border-l-transparent border-r-transparent border-t-black"
            />
          </p>
        </div>
        <app-image
          v-motion
          alt=""
          class="mx-auto h-[90px] md:h-auto"
          :height="200"
          :initial="{ opacity: 0 }"
          src="/images/illustrations/home_page_academy.svg"
          :visible-once="{
            opacity: 1,
            transition: {
              delay: 150,
              duration: 200,
              type: 'spring',
              stiffness: 250,
              damping: 25,
              mass: 0.5,
            },
          }"
        />
      </div>
    </template>

    <div class="flex justify-center">
      <app-button class="w-full sm:w-auto" color="dark" size="xl" to="/academy">
        Découvrir l'offre
      </app-button>
    </div>
  </app-page-section>
</template>

<script setup lang="ts">
const messageBubbleClass =
  "relative rounded bg-black text-white px-4 py-2 text-sm font-semibold shadow-lg shadow-black/5";
</script>
